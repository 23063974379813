// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-jsx": () => import("./../../../src/pages/account.jsx" /* webpackChunkName: "component---src-pages-account-jsx" */),
  "component---src-pages-callback-jsx": () => import("./../../../src/pages/callback.jsx" /* webpackChunkName: "component---src-pages-callback-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */)
}

