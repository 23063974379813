import React from 'react'
import {BrowserRouter} from "react-router-dom";
import Auth0ProviderWithHistory from './Authentication/auth0ProviderWithHistory';

function RootWrapper({children}) {
    return (
        <BrowserRouter>
            <Auth0ProviderWithHistory>
                {children}
            </Auth0ProviderWithHistory>            
        </BrowserRouter>
    )
}

export default RootWrapper
